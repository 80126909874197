import React, { useState } from "react";
import Layout from "../components/layout";
import { Link } from "gatsby"
import Socials from "../components/socials"
import VideoPlayer from "../components/videoVimeoPlayer"
import style from "./underconstruction.module.scss"
import videoStyle from "./ucVideoVimeoPlayer.module.styl"

export default () => {
  const [videoBufferend, setVideoBufferend] = useState(false);
  const mail = "info@fantasmagoria.it"

  return (
    <Layout
      seo={{ metaTitle: 'Landing' }}
      contentStyle={`${style.root} ${videoBufferend ? "" : "video-stop"}`}
      disableHeader
      disableFooter
    >
      <VideoPlayer
        className="video"
        url=" https://vimeo.com/538263281/36e1eb5b08"
        onVideoBufferend={setVideoBufferend}
        autoPlay
        style={videoStyle.root}
      />

      <div className="content">
        <div className="info">
          <span className="a1">Fantasmagoria in all its forms.</span><br />
          <span className="a2">Film Production Company</span><br />
          <span className="a3">based in Milan and Rome.</span>
        </div>
        <div className="mail">
          <a href={`mailto:${mail}`} target="_blank" rel="noreferrer">{mail}</a>
        </div>
        <Socials className="socials" />
      </div>
    </Layout >
  )
};