import React, { useRef, useEffect } from "react";
import defaultStyle from "./videoVimeoPlayer.module.styl"
import { parseVideo } from "../lib/youtube-vimeo-url-parser"
import Player from "@vimeo/player"


export default ({ url, onVideoBufferend, autoPlay, style, seeking = 0, background = true, className = null }) => {
  let src = ""

  const videoData = parseVideo(url);
  src = videoData.embededURL;

  if (background) {
    src += "?background=true"
  }

  const videFrameRef = useRef(null);

  var player;

  useEffect(() => {
    player = new Player(videFrameRef.current);

    player.on('loaded', () => {
      if (autoPlay) {
        player.play()
      } else {
        player.pause()
      }
    })

    // player.on('play', () => {
    //   console.log('Played the video');
    // });

    player.on('bufferend', () => {
      onVideoBufferend && onVideoBufferend(true);
    })

    // return () => {
    //   player.destroy()
    // };
  });

  useEffect(() => {
    console.log("Seek", seeking);
    player.setCurrentTime(seeking / 100)
  }, [seeking])

  return (
    <div className={(style ? style : defaultStyle.root) + (className ? " " + className : "")}>
      <div className="wrapper">
        <iframe
          ref={videFrameRef}
          width="100%"
          height="100%"
          src={src}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}